import React from 'react';
import Segment from '@components/segment';
import Layout from '@components/layout';
import Seo from '@components/seo';
import SocialProofQuoteCardST from '@components/staticSections/SocialProofQuoteCardST/SocialProofQuoteCardST';
import GetStartedST from '@components/staticSections/GetStartedST/GetStartedST';
import BgCardsTextST from '@components/staticSections/bgCardsTextST/BgCardsTextST';
import SocialProofFeedST from '@components/staticSections/socialProofFeedST/socialProofFeedST';
import LicensedPtSliderST from '@components/staticSections/LicensedPtSliderST/LicensedPtSliderST';
import Testimonial from '@components/sections/testimonial/testimonial';
import OutcomeST from '@components/staticSections/OutcomeST/OutcomeST';
import { LANG_US_EN } from '@/src/common/consts/lang';
import HeroListing from '@components/sections/heroListing/HeroListing';
import { HERO_IMAGE_VARIANT_KNEE_PAIN } from '@/src/common/consts/hero';
import { THEME_LIGHT, THEME_WHITE } from '@/src/common/consts/theme';
import Treatments from '@components/staticSections/Treatments/Treatments';
import { CTA_DATA_GOAL_ONBOARDING_VIEWED } from '@/src/common/consts/tracking';

const PatientPageUS = () => {
  const lang = LANG_US_EN;

  return (
    <Layout
      backgroundColor="#172D47!important"
      textColor="#06E55C!important"
      headerWitdh="header"
      headerStyle="flex--space-between"
      lang="us"
      showFooter
    >
      <Segment id={process.env.SEGMENT_MARKETING_LANDING_PAGES_US} />
      <Seo title="Get started" language="en" />

      <HeroListing
        imageVariant={HERO_IMAGE_VARIANT_KNEE_PAIN}
        imageAlt="Joint Academy Patient"
        title="Treat hip and knee pain from home"
        list={[
          { li: 'Personal physical therapists' },
          { li: 'Activities to reduce pain' },
          { li: 'Tools to track progress' },
        ]}
        showSmsTag
        smsTagLine="Get a text to download the app"
        showCerts
      />

      <SocialProofQuoteCardST
        lang={lang}
        dataTheme={THEME_WHITE}
        extraMargin="margin-top--lg"
      />

      <GetStartedST
        lang={lang}
        renderDownloadButton
        ctaButtonText="Get Started"
        ctaDataGoal={CTA_DATA_GOAL_ONBOARDING_VIEWED}
        ctaHref="#herotext"
      />

      <BgCardsTextST dataTheme={THEME_LIGHT} lang={lang} />

      <Treatments lang={lang} downloadhref="#herotext" />

      <SocialProofFeedST lang={lang} />

      <Testimonial
        dataTheme={THEME_WHITE}
        renderTitle
        titleClassName=""
        videoSrcURL="https://player.vimeo.com/video/345932429?color=fff&title=0&byline=0&portrait=0"
        videoTitle="“I can’t imagine a day without Joint Academy”"
      />

      <LicensedPtSliderST
        lang={lang}
        downloaddataGoal={CTA_DATA_GOAL_ONBOARDING_VIEWED}
        downloadhref="#herotext"
        downloadclassname="btn btn--fullwidth btn--md btn--primary btn--lgMob"
        downloadtext="Get started today"
      />

      <OutcomeST lang={lang} />
    </Layout>
  );
};

export default PatientPageUS;
